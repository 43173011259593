exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-atih-tsx": () => import("./../../../src/pages/atih.tsx" /* webpackChunkName: "component---src-pages-atih-tsx" */),
  "component---src-pages-bristol-project-tsx": () => import("./../../../src/pages/bristolProject.tsx" /* webpackChunkName: "component---src-pages-bristol-project-tsx" */),
  "component---src-pages-footer-tsx": () => import("./../../../src/pages/footer.tsx" /* webpackChunkName: "component---src-pages-footer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interests-tsx": () => import("./../../../src/pages/interests.tsx" /* webpackChunkName: "component---src-pages-interests-tsx" */),
  "component---src-pages-lets-talk-about-tsx": () => import("./../../../src/pages/letsTalkAbout.tsx" /* webpackChunkName: "component---src-pages-lets-talk-about-tsx" */),
  "component---src-pages-nav-tsx": () => import("./../../../src/pages/nav.tsx" /* webpackChunkName: "component---src-pages-nav-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

